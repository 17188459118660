import * as React from "react"
import PropTypes from "prop-types"

import HeaderUpdate from "./header-update"
import FooterUpdate from "./footer-update"

const Layout = ({ children }) => (
  <>
    <HeaderUpdate />
    <div>
      <main>
        {children}
      </main>
    </div>
    <FooterUpdate />
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout