import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import MenuDropdownServices from "./menu-dropdown-services"
import MenuDropdownCompany from "./menu-dropdown-company"
import MobileDrawerUpdate from "./mobile-drawer-update"

const HeaderUpdate = () => {
  return (
    <div className="bg-white w-full py-6 px-4">

      <header className="w-full max-w-screen-xl mx-auto">
        <nav className="flex items-center justify-between mx-auto">
          <div className="flex items-center">
            <Link to="/">
              <StaticImage
                src="../../images/logo/itproactive-logo.png"
                width="200"
              />
            </Link>

            <div className="hidden xl:flex items-center justify-center ml-12">
              <MenuDropdownServices />

              <MenuDropdownCompany />

              <div className="hidden xl:block">
                <Link to="/contact">
                  <button
                    className="text-base text-gray-900 hover:text-red-600 tracking-wider"
                  >
                    Contact
                  </button>
                </Link>
              </div>
            </div>
          </div>

          <div className="hidden xl:flex items-center">
            <a href="https://ww5.autotask.net/ClientPortal/login.aspx?ci=505040" target="_blank" rel="noopener noreferrer" className="text-base text-site-lightblue hover:text-red-600 tracking-wider font-semibold mr-6 uppercase">
              Support
            </a>
            <a href="tel:949-543-0230" className="w-auto bg-red-600 hover:bg-red-700 text-white font-display px-6 py-2 font-semibold uppercase tracking-wider rounded mr-6">
              (949) 543-0230
            </a>
            <Link to="assessment">
              <p className="text-base text-site-lightblue hover:text-red-600 tracking-wider font-semibold mr-6 uppercase">
                Sales
              </p>
            </Link>
            <a href="tel:9495430234" className="w-auto bg-red-600 hover:bg-red-700 text-white font-display px-6 py-2 font-semibold uppercase tracking-wider rounded">
              (949) 543-0234
            </a>
          </div>

          <MobileDrawerUpdate />
        </nav>
      </header>
    </div>
  )
}

export default HeaderUpdate